import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import {
  SectionContainer,
  SectionContent,
  SectionWrapper
} from "../common/Section";

import { Layout } from "../components/Layout";
import { Head } from "../components/Head";

import { CallToAction, LargeCallToAction } from "../components/CallToAction";
import { BenefitsCarousel, TestimonialsCarousel } from "../components/Carousel";
import { ContactHero } from "../components/Hero";


const ContactPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      benefits: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "benefit"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              heading
              description
              icon
            }
          }
        }
      }

      testimonials: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "testimonial"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              source
              reviewer
              review
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head
        title="Contact Us"
      />

      <SectionWrapper>
        <SectionContainer>
          <ContactHero
            heading="Contact Us"
            description={[
              "Fill out the form below and send the message."
            ]}
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="background.linkwater"
      >
        <LargeCallToAction
          heading="Servicing San Diego County for Over 20 Years. Our service professionals have earned over 3,700+ reviews !"
        />
      </SectionWrapper>

      <SectionWrapper
        padding="92px 0 0"
      >
        <SectionContainer>
          <BenefitsCarousel
            nodes={ data.benefits.edges }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <SectionContainer>
          <SectionContent
            justifyContent="flex-start"
            alignItems="center"
            textAlign="center"
            heading="Our service and professionals have earned over 3,700+ reviews!"
            headingWidth={{
              base: '100%',
              lg: '700px'
            }}
            headingMargin="0 auto"
            description={[
              "Vester Pest Control has helped thousands of property owners over the past 20 years. Here are what some of our customers have to say."
            ]}
            descriptionWidth={{
              base: '100%',
              sm: '580px'
            }}
          />
        </SectionContainer>
        <TestimonialsCarousel
          nodes={ data.testimonials.edges }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading="Request a Free Quote"
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export default ContactPage;
